<div class="bg-white">
    <div class="container mx-auto">
        <div class="flex sm:items-center px-4 py-3">
            <div class="pr-2">
            </div>
            <div class="text-gray-300 font-medium flex-1 text-center text-sm sm:text-base">
                Pricing & Plans
            </div>
        </div>
    </div>
</div>

<div class="container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh xl:min-h-screen mb-20">
    <div class="w-full md:w-10/12 xl:w-8/12 mx-auto text-center mt-1 mb-6">
        <p class="text-gray-600 sm:leading-8 text-sm sm:text-base">StartDeck offers a great deal of flexibility - switch
            between free, individual and team subscriptions at any time with no loss of data. Team Doc roster may scaled
            up or down at anytime.</p>

        <div class="text-xs italic text-danger text-center font-medium mt-1">
            <i class="fa fa-info-circle"></i> Try free, no credit card required.
        </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-10">
        <div class="relative bg-white flex flex-col justify-between shadow-card rounded-lg p-4 sm:p-5 text-center border-2 border-transparent transition-all duration-300"
            [ngClass]="{'border-opacity-75 border-warning' : isUserLoggedIn && user.subscription == false, 'hover:border-opacity-50 hover:border-warning' : !isUserLoggedIn , '' : isUserLoggedIn && user.subscription}">

            <span *ngIf="isUserLoggedIn && user.subscription == false" class="absolute bg-warning inline-flex p-8 text-white" style="top: -2px; right: -2px; border-top-right-radius: 8px; clip-path: polygon(100% 0%, 0% 0%, 100% 100%);">
                <svg class="inline-flex absolute top-3 right-2" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4.95455 9.77612L7.81818 11.7993L15.0455 5L16 6.04478L7.81818 15L4 10.8209L4.95455 9.77612Z"/>
                </svg>
            </span>

            <div class="mt-1">
                <h4 class="text-gray-300 font-bold sm:text-lg sm:mb-1.5">Individual Free Plan</h4>

                <div
                    class="flex flex-wrap items-center justify-center mb-4 sm:mb-5 pb-4 sm:pb-5 border-b border-dashed border-gray-light">
                    <div class="text-warning text-3xl md:text-4xl leading-10 font-extrabold inline">
                        $0
                    </div>
                    <span class="text-gray-900 text-sm sm:text-base">&nbsp;/&nbsp;</span>
                    <span class="text-gray-900 text-sm sm:text-base mt-0.5">MONTH</span>
                </div>

                <span class="inline-block mb-4 px-5 p-1 text-xs font-medium bg-warning bg-opacity-10 text-warning">Free
                    Use Allowances</span>

                <ul
                    class="bullet-warning-check text-gray-300 font-normal text-xs leading-5 pl-5 md:text-sm sm:leading-6 text-left">
                    <li class="mb-4">3 Projects per month</li>
                    <li class="mb-4">3 Templates per month</li>
                    <li class="mb-4">3 eSignatures and/or Deliveries per month</li>
                    <li class="mb-4">Unlimited Use of StartDeck Writer Add-on</li>
                    <li>Unlimited Use of StartDeck Tables Add-on</li>
                </ul>
            </div>

            <div class="mt-4 sm:mt-5 pt-4 sm:pt-5 border-t border-dashed border-gray-light">
                <a *ngIf="!isUserLoggedIn" href="javascript:void(0)" (click)="freePlanClickEvent()"
                    class="inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-warning">
                    Try Free Now
                </a>

                <a *ngIf="isUserLoggedIn && user.subscription == false" href="javascript:void(0)"
                    class="inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-warning cursor-default uppercase">
                    <svg class="w-4 h-4 -mt-0.5 inline-block mr-1" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4.95455 9.77612L7.81818 11.7993L15.0455 5L16 6.04478L7.81818 15L4 10.8209L4.95455 9.77612Z"/>
                    </svg>
                    Selected
                </a>

                <a *ngIf="isUserLoggedIn && user.subscription == true" href="javascript:void(0)"
                    class="inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-gray-900 cursor-not-allowed">
                    Try Now
                </a>

                <!-- <button
                    (click)="isUserLoggedIn && user.subscription == false || isUserLoggedIn && user.subscription ? '' : freePlanClickEvent()"
                    type="button"
                    class="rounded w-full sm:w-auto px-2 sm:px-10 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300"
                    [ngClass]="isUserLoggedIn && user.subscription == false || isUserLoggedIn && user.subscription ? 'bg-gray-900 cursor-not-allowed' :'bg-warning'">Try
                    Free Now</button> -->
            </div>
        </div>

        <div class="relative bg-white flex flex-col justify-between shadow-card rounded-lg p-4 sm:p-5 text-center border-2 border-transparent transition-all duration-300"
            [ngClass]="hasProSubscription ? 'border-opacity-75 border-success' :  'hover:border-opacity-50 hover:border-success'">

            <span *ngIf="isUserLoggedIn && this.user.subscription == true && this.user.subscription_plan == 'pro'" class="absolute bg-success inline-flex p-8 text-white" style="top: -2px; right: -2px; border-top-right-radius: 8px; clip-path: polygon(100% 0%, 0% 0%, 100% 100%);">
                <svg class="inline-flex absolute top-3 right-2" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4.95455 9.77612L7.81818 11.7993L15.0455 5L16 6.04478L7.81818 15L4 10.8209L4.95455 9.77612Z"/>
                </svg>
            </span>

            <div class="mt-1">
                <h4 class="text-gray-300 font-bold sm:text-lg sm:mb-1.5">Individual Pro Subscription</h4>

                <div
                    class="flex flex-wrap items-center justify-center mb-4 sm:mb-5 pb-4 sm:pb-5 border-b border-dashed border-gray-light">
                    <div class="text-success text-3xl md:text-4xl leading-10 font-extrabold inline">
                        $89.00
                    </div>
                    <span class="text-gray-900 text-sm sm:text-base">&nbsp;/&nbsp;</span>
                    <span class="text-gray-900 text-sm sm:text-base mt-0.5">MONTH</span>
                </div>

                <span class="inline-block mb-4 px-5 p-1 text-xs font-medium bg-success bg-opacity-10 text-success">Free
                    14 day trial</span>

                <ul
                    class="bullet-success-check text-gray-300 font-normal text-xs leading-5 pl-5 md:text-sm sm:leading-6 text-left">
                    <li class="mb-4">Unlimited Projects</li>
                    <li class="mb-4">Unlimited Templates</li>
                    <li class="mb-4">Unlimited eSignatures and Deliveries</li>
                    <li class="mb-4">Unlimited Use of StartDeck Writer Add-on</li>
                    <li class="mb-4">Unlimited Use of StartDeck Tables Add-on</li>
                    <li>Unlimited Access to Premium Templates</li>
                </ul>
            </div>

            <div class="mt-4 sm:mt-5 pt-4 sm:pt-5 border-t border-dashed border-gray-light flex flex-wrap gap-4 justify-center">
                <a *ngIf="!isUserLoggedIn" href="javascript:void(0)" (click)="proPlanClickEvent()"
                    class=" md:w-3/12 inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-success">
                    Try Free Now<br>
                    <span style="font-size: 9px;">(Excludes Premium Templates)</span>
                </a>

                <a *ngIf="isUserLoggedIn && this.user.subscription == false" href="javascript:void(0)"
                    (click)="proPlanClickEvent()"
                    class=" md:w-3/12 inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-success">
                    Try Free Now<br>
                    <span style="font-size: 9px;">(Excludes Premium Templates)</span>
                </a>

                <a *ngIf="isUserLoggedIn && this.user.subscription == true && this.user.subscription_plan == 'pro'"
                    href="javascript:void(0)" (click)="proPlanClickEvent('selected')"
                    class=" md:w-3/12 inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-success cursor-default uppercase">
                    <svg class="w-4 h-4 -mt-0.5 inline-block mr-1" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4.95455 9.77612L7.81818 11.7993L15.0455 5L16 6.04478L7.81818 15L4 10.8209L4.95455 9.77612Z"/>
                    </svg>
                    Selected
                </a>

                <a *ngIf="isUserLoggedIn && this.user.subscription == true && this.user.subscription_plan != 'pro'"
                    href="javascript:void(0)" (click)="proPlanClickEvent()"
                    class=" md:w-3/12 inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-success">
                    Try Free Now<br>
                    <span style="font-size: 9px;">(Excludes Premium Templates)</span>
                </a>
                <a *ngIf="(isUserLoggedIn && this.user.subscription_plan != 'pro')||!isUserLoggedIn"
                   href="javascript:void(0)" (click)="proPlanClickEvent()"
                   class=" md:w-3/12                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            inline-block rounded w-full sm:w-auto min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-success">
                   Subscribe<br>
                  <span style="font-size: 9px;">(Includes Premium Templates)</span>
               </a>
                <!-- <button (click)="hasProSubscription ? '' : proPlanClickEvent()" type="button"
                    class="rounded w-full sm:w-auto px-2 sm:px-10 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300"
                    [ngClass]="hasProSubscription ? 'bg-gray-900 cursor-not-allowed' : 'bg-success'">Try
                    Free Now
                </button> -->
            </div>
        </div>

        <div class="relative bg-white flex flex-col justify-between shadow-card rounded-lg p-4 sm:p-5 text-center border-2 border-transparent transition-all duration-300"
            [ngClass]="hasTeamDocSubscription ? 'border-opacity-75 border-primary' : 'hover:border-opacity-50 hover:border-primary'">

            <span *ngIf="isUserLoggedIn && this.user.subscription == true && this.user.subscription_plan == 'team-docs'" class="absolute bg-primary inline-flex p-8 text-white" style="top: -2px; right: -2px; border-top-right-radius: 8px; clip-path: polygon(100% 0%, 0% 0%, 100% 100%);">
                <svg class="inline-flex absolute top-3 right-2" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4.95455 9.77612L7.81818 11.7993L15.0455 5L16 6.04478L7.81818 15L4 10.8209L4.95455 9.77612Z"/>
                </svg>
            </span>

            <div class="mt-1">
                <h4 class="text-gray-300 font-bold sm:text-lg sm:mb-1.5">Team Docs Subscription</h4>

                <div
                    class="flex flex-wrap items-center justify-center mb-4 sm:mb-5 pb-4 sm:pb-5 border-b border-dashed border-gray-light">
                    <div class="text-primary text-3xl md:text-4xl leading-10 font-extrabold inline">
                        $99.00
                    </div>
                    <span class="text-gray-900 text-sm sm:text-base">&nbsp;/&nbsp;</span>
                    <span class="text-gray-900 text-sm sm:text-base mt-0.5">USER</span>
                    <span class="text-gray-900 text-sm sm:text-base">&nbsp;/&nbsp;</span>
                    <span class="text-gray-900 text-sm sm:text-base mt-0.5">MONTH</span>
                </div>

                <span class="inline-block mb-4 px-5 p-1 text-xs font-medium bg-primary bg-opacity-10 text-primary">Free
                    14 day trial</span>

                <ul
                    class="bullet-primary-check text-gray-300 font-normal text-xs leading-5 pl-5 md:text-sm sm:leading-6 text-left">
                    <li class="mb-4">Unlimited Projects</li>
                    <li class="mb-4">Unlimited Templates</li>
                    <li class="mb-4">Unlimited eSignatures and Deliveries</li>
                    <li class="mb-4">Unlimited Use of StartDeck Writer Add-on</li>
                    <li class="mb-4">Unlimited Use of StartDeck Tables Add-on</li>
                    <li class="mb-4">Centralized, Branded Team Templates</li>
                    <li class="mb-4">Streamlined Team Project and File Management</li>
                    <li class="mb-4">Simple, Secure Team Sharing on G Drive</li>
                    <li class="mb-4">Simple Team Roster and Member Role Management</li>
                    <li class="mb-4">Team-wide Workflow Visibility</li>
                    <li>Unlimited Access to Premium Templates</li>

                </ul>

                <a [routerLink]="'/team-docs'"
                    class="inline-block px-8 py-1 mt-4 border border-primary text-xs font-medium rounded-full text-primary bg-white transition-all duration-300 hover:text-white hover:bg-primary hover:border-primary">
                    Learn More <i class="fa fa-angle-double-right text-sm"></i>
                </a>
            </div>
          
            <div class="mt-4 sm:mt-5 pt-4 sm:pt-5 border-t border-dashed border-gray-light flex flex-wrap gap-4 justify-center">
                <a *ngIf="isUserLoggedIn==false" href="javascript:void(0)" (click)="teamDocsPlanClickEvent()"
                    class=" md:w-3/12 inline-block rounded w-full  min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-primary">
                    Try Free Now<br>
                    <span style="font-size: 9px;">(Excludes Premium Templates)</span>
                </a>

                <a *ngIf="isUserLoggedIn && this.user.subscription == false" href="javascript:void(0)"
                    (click)="teamDocsPlanClickEvent()"
                    class=" md:w-3/12 inline-block rounded w-full  min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-primary">
                    Try Free Now<br>
                    <span style="font-size: 9px;">(Excludes Premium Templates)</span>

                </a>

                <a *ngIf="isUserLoggedIn && this.user.subscription == true && this.user.subscription_plan == 'team-docs'"
                    href="javascript:void(0)" (click)="teamDocsPlanClickEvent()"
                    class="md:w-3/12 inline-block rounded w-full min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-primary cursor-default uppercase">
                    <svg class="w-4 h-4 -mt-0.5 inline-block mr-1" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4.95455 9.77612L7.81818 11.7993L15.0455 5L16 6.04478L7.81818 15L4 10.8209L4.95455 9.77612Z"/>
                    </svg>
                    Selected
                </a>

                <a *ngIf="isUserLoggedIn && this.user.subscription == true && this.user.subscription_plan != 'team-docs'"
                    href="javascript:void(0)" (click)="teamDocsPlanClickEvent()"
                    class=" md:w-3/12 inline-block rounded w-full min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-primary">
                    Try Free Now<br>
                    <span style="font-size: 9px;">(Excludes Premium Templates)</span>
                </a>
                <a *ngIf="(isUserLoggedIn && this.user.subscription_plan != 'team-docs') ||!isUserLoggedIn"
                    href="javascript:void(0)" (click)="teamDocsPlanClickEvent()"
                    class=" md:w-3/12 inline-block rounded w-full min-w-44 px-2 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300 bg-primary">
                    Subscribe<br>
                    <span style="font-size: 9px;">(Includes Premium Templates)</span>
                </a>
                

                <!-- <button (click)="teamDocsPlanClickEvent()" type="button"
                    class=" rounded w-full sm:w-auto px-2 sm:px-10 py-2 sm:py-2.5 text-white text-sm tracking-wider font-medium focus:outline-none hover:shadow-btn transition-all duration-300"
                    [ngClass]="hasTeamDocSubscription ? 'bg-gray-900 cursor-not-allowed' : 'bg-primary'">
                    Try Free Now
                </button> -->
            </div>
        </div>
    </div>
</div>